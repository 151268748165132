import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import intro from "../../images/intro.jpg";
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import iris from "../../images/iris.jpeg";
import avi from "../../images/avi.jpg";
export default function About(){
    return (
        <>
        <NavBar />
        <AboutUs />
        <Input />
        <Footer />
        </>
    );
}





const AboutUs = () => {
    return (
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
                <div className="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">About Us</h1>
                    <p className="font-normal text-base leading-6 text-gray-600 ">American Global Technologies, a leading low voltage consulting firm in San Diego since 2012, specializes in advanced audio-visual, security, smart home, and lighting solutions for commercial and residential clients, ensuring top-quality, efficient, and innovative system designs for enhanced safety and comfort.</p>
                </div>
                <div className="w-full lg:w-8/12 ">
                    <img className="w-full h-full rounded-lg" src={intro} alt="San Diego" />
                </div>
            </div>

            <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                <div className="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">Our Story</h1>
                    <p className="font-normal text-base leading-6 text-gray-600 ">As a pioneer in low voltage consulting, American Global Technologies has consistently led the way in delivering advanced solutions across a spectrum of needs. From cutting-edge audio-visual systems that transform any room into a dynamic media hub, to comprehensive security setups that guard your peace of mind, to smart home technologies that adjust to your life's rhythms, and innovative lighting solutions that illuminate possibilities—our expertise is as diverse as the clients we serve.</p>
                </div>
                <div className="w-full lg:w-8/12 lg:pt-8">
                    <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                        <div className="p-4 pb-6 flex justify-center flex-col items-center">
                            <img className="md:block hidden rounded-md h-40" src={avi} />
                            <img className="md:hidden block rounded-md " src={avi} />
                            <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Avi</p>
                        </div>
                        <div className="p-4 pb-6 flex justify-center flex-col items-center">
                            <img className="md:block hidden rounded-md h-40" src={iris} />
                            <img className="md:hidden block rounded-md " src={iris} />
                            <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Iris</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




function Input(){

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);
    const sendEmail = (e) => {
      e.persist();
      e.preventDefault();
      setIsSubmitting(true);
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          e.target,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            setStateMessage('Message sent!');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          },
          (error) => {
            setStateMessage('Something went wrong, please try again later');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          }
        );
      
      // Clears the form after sending the email
      e.target.reset();
    };

    return (
        <section class="text-gray-600 body-font relative">
        <form onSubmit={sendEmail}>
        <div class="container px-5 py-24 mx-auto">
            <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
            <p class="lg:w-2/5 mx-auto leading-relaxed text-base">Reach out to us with your inquiries, feedback, or to discuss how our solutions can meet your needs.</p>
            </div>
            <div class="lg:w-1/2 md:w-2/3 mx-auto">
            <div class="flex flex-wrap -m-2">
                <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                    <input type="text" id="name" name="user_name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-navy-blue focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                </div>
                </div>
                <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                    <input type="email" id="email" name="user_email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-navy-blue focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                </div>
                </div>
                <div class="p-2 w-full">
                <div class="relative">
                    <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                    <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-navy-blue focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                </div>
                </div>
                <div class="p-2 w-full">
                <button class="flex mx-auto text-white bg-navy-blue border-0 py-2 px-8 focus:outline-none hover:bg-navy-blue rounded text-lg" disabled={isSubmitting}>Contact Us</button>
                {stateMessage && <p>{stateMessage}</p>}
                </div>
            </div>
            </div>
        </div>
        </form>
        </section>

    );
}
