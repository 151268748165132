import React from "react";
import NavBar from '../../components/NavBar';
import Footer from "../../components/Footer";
import audioVisual from "../../images/audioVisual.jpg";
import cameras from "../../images/cameras.jpg";
import intercoms from "../../images/intercoms.jpg";
import ledLighting from "../../images/ledLighting.jpg";
import lutron from "../../images/lutron.jpg";
import profAudio from "../../images/profAudio.jpg";
import smartHome from "../../images/smartHome.jpg";
import accessControl from "../../images/accessControl.jpg";
import outdoorSystems from "../../images/outdoorSystems.jpg";
import "./text.js";
export default function Services(){
    return (
        <>
        <NavBar />
        <ServiceList />
        <Footer />
        </>
    );
}


const {
    audioVisualText,
    accessControlText,
    securityCameraText,
    smartHomeText,
    intercomsText,
    professionalAudioText,
    lutronLightingText,
    outdoorSystemsText,
    ledLightingText,
} = require('./text.js');


const products = [
    {
      id: 1,
      name: 'Audio Visual',
      description: audioVisualText,
      imageSrc: audioVisual,
      imageAlt: 'Audio Visual',
    },
    {
        id: 2,
        name: 'Professional Audio',
        description: professionalAudioText,
        imageSrc: profAudio,
        imageAlt: 'Professional Audio',
    },
    {
        id: 3,
        name: 'Outdoor Systems',
        description: outdoorSystemsText,
        imageSrc: outdoorSystems,
        imageAlt: 'Outdoor System',
    },
    {
      id: 4,
      name: 'Access Control',
      description: accessControlText,
      imageSrc: accessControl,
      imageAlt: 'Access Control',
    },
    {
        id: 5,
        name: 'Intercoms',
        description: intercomsText,
        imageSrc: intercoms,
        imageAlt: 'Intercom',
    },
    {
      id: 6,
      name: 'Security Cameras',
      description: securityCameraText,
      imageSrc: cameras,
      imageAlt: 'Security Cameras',
    },
    {
      id: 7,
      name: 'Smart Home',
      description: smartHomeText,
      imageSrc: smartHome,
      imageAlt: 'Smart Home',
    },
    
    
    {
        id: 8,
        name: 'Lutron Lighting',
        description: lutronLightingText,
        imageSrc: lutron,
        imageAlt: 'Lutron Lighting',
    },
    
    {
        id: 9,
        name: 'LED Lighting',
        description: ledLightingText,
        imageSrc: ledLighting,
        imageAlt: 'LED Lighting',
    },
  ]
  
    function ServiceList() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Products</h2>
  
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-9">
            {products.map((product) => (
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    loading="lazy"
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
                <p className="mt-1 text-md font-normal text-gray-900">{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  