const audioVisualText = "Transform your sensory experience with our premier audio-visual service, delivering immersive visuals and crisp, high-quality sound. Elevate your entertainment and communication to new heights with our state-of-the-art technology."

const accessControlText = "Empower your audio control experience with our cutting-edge, low-voltage consulting service. Achieve precision and efficiency in sound customization, seamlessly integrating advanced technology for a personalized, low-voltage audio solution."

const securityCameraText = "Enhance your security with our state-of-the-art surveillance system, backed by expert low-voltage consulting. Enjoy peace of mind as our advanced technology provides seamless monitoring and control, ensuring a robust and efficient surveillance solution tailored to your needs."

const smartHomeText = "Transform your living space into an intelligent haven with our smart home systems, guided by expert low-voltage consulting. Experience seamless integration and personalized control as our advanced technology enhances comfort, security, and efficiency, making your home truly smart and connected."

const intercomsText = "Upgrade your communication experience with our cutting-edge intercom system, backed by expert low-voltage consulting. Seamlessly connect with family members or colleagues, ensuring clear and efficient communication within your space through our advanced and user-friendly intercom solution."

const professionalAudioText = "Elevate your audio experience to professional standards with our advanced audio solutions, guided by expert low-voltage consulting. Immerse yourself in superior sound quality and tailored configurations, ensuring a seamless and exceptional audio environment for your professional needs."

const lutronLightingText = "Illuminate your space with sophistication using our Lutron lighting solutions, expertly implemented through low-voltage consulting. Enjoy personalized control, energy efficiency, and ambiance optimization as our advanced lighting system transforms your environment with style and functionality."

const outdoorSystemsText = "Extend your lifestyle beyond walls with our outdoor systems, meticulously designed and implemented with expert low-voltage consulting. Experience seamless integration of lighting, audio, and surveillance, enhancing your outdoor spaces with technology that brings both comfort and security to new horizons."

const ledLightingText = "Illuminate your surroundings with energy-efficient brilliance through our LED lighting solutions, meticulously crafted with expert low-voltage consulting. Experience a harmonious blend of cutting-edge technology and cost-effective illumination, enhancing your spaces with vibrant, customizable LED lighting tailored to your preferences."


module.exports = {
    audioVisualText,
    accessControlText,
    securityCameraText,
    smartHomeText,
    intercomsText,
    professionalAudioText,
    lutronLightingText,
    outdoorSystemsText,
    ledLightingText,
  };